<template>
  <div class="mod-config">
    <el-form :inline="true" :model="dataForm">
      <el-form-item>
        <el-select
          v-model="dataForm.cid"
          clearable
          :placeholder="companyName ? companyName : '按代理商筛选'"
          filterable
          @focus="companySelectFocus"
          style="margin-right: 10px"
          @change="changeCompany"
        >
          <el-option
            v-for="item in companyOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-select
          v-model="dataForm.bid"
          clearable
          placeholder="按商家筛选"
          filterable
          @focus="businessSelectFocus"
          style="margin-right: 10px"
        >
          <el-option
            v-for="item in businessOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-select
          v-model="dataForm.status"
          clearable
          placeholder="按状态筛选"
          style="margin-right: 10px"
        >
          <el-option
            v-for="item in statusOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button @click="getDataList()">查询</el-button>
        <el-button type="primary" @click="addOrUpdateHandle()">新增</el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="dataList"
      border
      size="medium"
      v-loading="dataListLoading"
      style="width: 100%"
    >
      <el-table-column
        prop="id"
        header-align="center"
        align="center"
        label="编号"
      >
      </el-table-column>
      <el-table-column
        prop="userNickName"
        header-align="center"
        align="center"
        label="用户"
      >
      </el-table-column>
      <el-table-column
        prop="companyName"
        header-align="center"
        align="center"
        label="代理商"
      >
      </el-table-column>
      <el-table-column
        prop="businessName"
        header-align="center"
        align="center"
        label="商家"
      >
      </el-table-column>
      <el-table-column
        prop="invoiceHeader"
        header-align="center"
        align="center"
        label="发票抬头"
      >
      </el-table-column>
      <el-table-column
        prop="invoiceNumber"
        header-align="center"
        align="center"
        label="发票税号"
      >
      </el-table-column>
      <el-table-column
        prop="money"
        header-align="center"
        align="center"
        label="金额"
      >
        <template slot-scope="scope">
          <span>{{ scope.row.money / 100 }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="email"
        header-align="center"
        align="center"
        label="邮箱"
      >
      </el-table-column>
      <el-table-column
        prop="orderNum"
        header-align="center"
        align="center"
        label="订单号"
      >
      </el-table-column>
      <el-table-column
        prop="status"
        header-align="center"
        align="center"
        label="状态"
      >
        <template slot-scope="scope">
          <span v-if="scope.row.status == 0">申请中</span>
          <span v-if="scope.row.status == 1">已开具</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="createDate"
        header-align="center"
        align="center"
        label="创建时间"
      >
      </el-table-column>
      <el-table-column
        fixed="right"
        header-align="center"
        align="center"
        width="150"
        label="操作"
      >
        <template slot-scope="scope">
          <el-button
            v-if="scope.row.status == 0"
            type="text"
            size="small"
            @click="kaijuHandle(scope.row.id)"
            >开具发票</el-button
          >
          <el-button
            type="text"
            size="small"
            @click="deleteHandle(scope.row.id)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      style="text-align: center; margin-top: 10px"
      @size-change="sizeChangeHandle"
      @current-change="currentChangeHandle"
      :current-page="pageIndex"
      :page-sizes="[10, 20, 50, 100, 1000, 10000]"
      :page-size="pageSize"
      :total="totalPage"
      layout="total, sizes, prev, pager, next, jumper"
    >
    </el-pagination>
    <!-- 弹窗, 新增 / 修改 -->
    <add-or-update
      v-if="addOrUpdateVisible"
      ref="addOrUpdate"
      @refreshDataList="getDataList"
    ></add-or-update>
  </div>
</template>

<script>
import {
  deleteTaxById,
  getTaxList,
  getBusinessList,
  getCompanyList,
  updateTax,
} from "../../api/api";
export default {
  data() {
    return {
      // 代理商选择器列表
      companyOptions: [],
      // 代理商选择器默认展示
      companyName: "",
      businessOptions: [],
      dataForm: {
        cid: "",
        bid: "",
        status: "",
      },
      statusOptions: [
        {
          value: 0,
          label: "申请中",
        },
        {
          value: 1,
          label: "已开具",
        },
      ],
      dataList: [],
      pageIndex: 1,
      pageSize: 10,
      totalPage: 0,
      dataListLoading: false,
      dataListSelections: [],
      addOrUpdateVisible: false,
    };
  },
  created() {
    this.getDataList();
  },
  methods: {
    // 商家选择框获得焦点事件
    businessSelectFocus() {
      if (this.dataForm.cid == "" || this.dataForm.cid == null) {
        this.$message.error("请先选择代理商");
        return;
      }
      if (this.businessOptions.length > 0) {
        return;
      }
      this.dataListLoading = true;
      getBusinessList({ limit: 99999, cid: this.dataForm.cid }).then((data) => {
        if (data && data.code === 0) {
          let resultList = data.data.list;
          if (resultList.length > 0) {
            this.businessOptions = [];
            for (let item of resultList) {
              this.businessOptions.push({
                value: item.id,
                label: item.name,
              });
            }
          }
        }
        this.dataListLoading = false;
      });
    },
    // 选择代理商的事件
    changeCompany(value) {
      this.dataForm.bid = "";
      this.businessName = "";
      this.dataForm.cid = value;
    },
    // 代理商选择框获得焦点事件
    companySelectFocus() {
      if (this.companyOptions.length > 0) {
        return;
      }
      let userInfo = JSON.parse(localStorage.getItem("userInfo"));
      if (userInfo.type == 1) {
        return;
      }

      this.dataListLoading = true;
      getCompanyList({ limit: 9999 }).then((data) => {
        if (data && data.code === 0) {
          let resultList = data.data.list;
          if (resultList.length > 0) {
            this.companyOptions = [];
            for (let item of resultList) {
              this.companyOptions.push({
                value: item.id,
                label: item.name,
              });
            }
          }
        }
        this.dataListLoading = false;
      });
    },
    // 获取数据列表
    getDataList() {
      this.dataListLoading = true;
      this.dataForm.page = this.pageIndex;
      this.dataForm.limit = this.pageSize;

      getTaxList(this.dataForm).then((data) => {
        if (data && data.code === 0) {
          this.dataList = data.data.list;
          this.totalPage = data.data.totalCount;
        } else {
          this.dataList = [];
          this.totalPage = 0;
        }
        this.dataListLoading = false;
      });
    },
    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val;
      this.pageIndex = 1;
      this.getDataList();
    },
    // 当前页
    currentChangeHandle(val) {
      this.pageIndex = val;
      this.getDataList();
    },
    // 开具发票
    kaijuHandle(id) {
      this.$confirm(`确定已经开具发票吗？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        updateTax({
          id,
        }).then((data) => {
          if (data && data.code === 0) {
            this.$message({
              message: data.msg,
              type: "success",
              duration: 1500,
              onClose: () => {
                this.getDataList();
              },
            });
          } else {
            this.$message.error(data.msg);
          }
        });
      });
    },
    // 删除
    deleteHandle(id) {
      this.$confirm(`确定要进行删除吗？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        deleteTaxById({
          id,
        }).then((data) => {
          if (data && data.code === 0) {
            this.$message({
              message: data.msg,
              type: "success",
              duration: 1500,
              onClose: () => {
                this.getDataList();
              },
            });
          } else {
            this.$message.error(data.msg);
          }
        });
      });
    },
  },
};
</script>
